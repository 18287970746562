<template>
  <div class="bg-gray-800">
    <div class="flex flex-wrap">
      <div class="w-full">
        <BlockGroup
          :label="'Properties'"
          :closed="false"
        >
          <InputWrapper
            type="toggle"
            :label="'Visible'"
            :value="node.visible"
            @change="onChangeVisible"
          />

          <InputWrapper
            type="position"
            :label="'Position'"
            :values="position"
            :step="0.1"
            @change="onChangePosition"
          />

          <InputWrapper
            type="position"
            :label="'Rotation'"
            :values="rotation"
            :step="0.1"
            @change="onChangeRotation"
          />

          <InputWrapper
            type="position"
            :label="'Scale'"
            :values="scale"
            :min="0"
            :step="0.1"
            :lockable="true"
            @change="onChangeScale"
          />
        </BlockGroup>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from './NodeSettingMixin'

export default {
  name: 'Object3dBlock',

  mixins: [mixin],

  computed: {

    position () {
      return {
        x: this.node.position.x,
        y: this.node.position.y,
        z: this.node.position.z
      }
    },

    rotation () {
      return {
        x: this.node.rotation.x,
        y: this.node.rotation.y,
        z: this.node.rotation.z
      }
    },

    scale () {
      return {
        x: this.node.scale.x,
        y: this.node.scale.y,
        z: this.node.scale.z
      }
    }

  },

  methods: {

    onChangeVisible () {
      this.gui.updateNode(() => {
        this.node.visible = !this.node.visible
      })
    },

    onChangePosition (values) {
      this.gui.updateNode(() => {
        if (values.x !== undefined) this.node.position.x = values.x
        if (values.y !== undefined) this.node.position.y = values.y
        if (values.z !== undefined) this.node.position.z = values.z
      })
    },

    onChangeRotation (values) {
      this.gui.updateNode(() => {
        if (values.x !== undefined) this.node.rotation.x = values.x
        if (values.y !== undefined) this.node.rotation.y = values.y
        if (values.z !== undefined) this.node.rotation.z = values.z
      })
    },

    onChangeScale (values) {
      this.gui.updateNode(() => {
        if (values.x !== undefined) this.node.scale.x = values.x
        if (values.y !== undefined) this.node.scale.y = values.y
        if (values.z !== undefined) this.node.scale.z = values.z
      })
    }
  }
}
</script>
