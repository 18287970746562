<template>
  <div>
    <div class="flex items-center justify-between pt-0.5 text-xs bg-gray-800 tabs sub-tabs border-b" :class="nodeBorderClass">
      <div class="font-bold rounded-t-sm tab">
        <fa-icon
          v-if="hasIcon"
          :icon="nodeIcon"
          :class="nodeIconClass"
          size="sm"
          class="mr-2"
        />
        <fa-icon
          v-else
          icon="circle"
          size="xs"
          class="mr-2 text-gray-400 opacity-25"
        />
        <span class="text-white truncate">
          <template v-if="node.name">
            {{ node.name }}
          </template>
          <template v-else>{{ node.uuid }}</template>
        </span>
      </div>
      <div
        class="px-2 mr-2 text-white cursor-pointer hover:underline hover:text-white"
        @click.prevent="$emit('close')"
      >
        <fa-icon icon="xmark" />
      </div>
      
    </div>
<!--     
    <div class="flex items-center justify-between pt-3 text-xs bg-gray-700 border-b-2 border-gray-800 tabs sub-tabs">
      <a
        href="#"
        class="font-bold bg-gray-800 rounded-t-sm tab"
      >

        <fa-icon
          v-if="hasIcon"
          :icon="nodeIcon"
          :class="nodeIconClass"
          size="sm"
          class="mr-2"
        />
        <fa-icon
          v-else
          icon="circle"
          size="xs"
          class="mr-2 text-gray-400 opacity-25"
        />
        <span class="text-gray-100 truncate">
          <template v-if="node.name">
            {{ node.name }}
          </template>
          <template v-else>{{ node.uuid }}</template>
        </span>
      </a>
      <div
          class="px-2 cursor-pointer hover:underline"
          @click.prevent="$emit('close')"
        >
          Close
        </div>
    </div> -->

    <InfoBlock
      v-if="showInfoBlock"
      :node="node"
      :gui="gui"
    />

    <BookmarkBlock
      v-if="isPrevizBookmark"
      :node="node"
      :gui="gui"
    />

    <TextureBlock
      v-if="isTexture"
      :node="node"
      :gui="gui"
    />

    <MaterialBlock
      v-if="isMaterial"
      :node="node"
      :gui="gui"
    />

    <MaterialTexturesBlock
      v-if="isMaterial"
      :node="node"
      :gui="gui"
    />

    <Object3DBlock
      v-if="showBaseOpts"
      :node="node"
      :gui="gui"
    />

    <MeshBlock
      v-if="isMesh"
      :node="node"
      :gui="gui"
      @view="$emit('view', $event)"
    />

    <LightBlock
      v-if="isLight && !isGlobalLight"
      :node="node"
      :gui="gui"
    />

    <AnimationBlock
      v-if="isAnimation"
      :node="node"
      :gui="gui"
    />

    <ObjectBlock
      v-if="showObjectBlock"
      :node="node"
      :gui="gui"
    />
  </div>
</template>
<script>
import Object3DBlock from './blocks/Object3DBlock'
import mixin from './NodeMixin'
const BlockHeader = () => import('@components/editor/scene/blocks/BlockHeader')
const InfoBlock = () => import('./blocks/InfoBlock')
const MeshBlock = () => import('./blocks/MeshBlock')
const TextureBlock = () => import('./blocks/TextureBlock')
const MaterialBlock = () => import('./blocks/MaterialBlock')
const MaterialTexturesBlock = () => import('./blocks/MaterialTexturesBlock')
const LightBlock = () => import('./blocks/LightBlock')
const AnimationBlock = () => import('./blocks/AnimationBlock')
const ObjectBlock = () => import('./blocks/ObjectBlock')
const BookmarkBlock = () => import('./blocks/BookmarkBlock')

export default {
  name: 'ModelScenegraphNodeDetail',

  components: {
    BlockHeader,
    InfoBlock,
    MeshBlock,
    TextureBlock,
    MaterialBlock,
    MaterialTexturesBlock,
    LightBlock,
    AnimationBlock,
    Object3DBlock,
    ObjectBlock,
    BookmarkBlock
  },

  mixins: [mixin],

  props: {
    gui: {
      type: Object,
      required: true
    }
  },

  computed: {
    showBaseOpts () {
      return !(this.isLight || this.isMaterial || this.isTexture || this.isAnimation || this.isIgnoredGroup || this.isPrevizBookmark)
    },

    showInfoBlock () {
      return false
      return true // !(this.isMaterial)
    },

    showObjectBlock () {
      return !(this.isMaterial || this.isTexture || this.isIgnoredGroup || this.isGlobalLight || this.isPrevizBookmark)
    }
  }
}
</script>
