import InputWrapper from '@components/editor/module/inputs/InputWrapper'
import BlockHeader from '@components/editor/scene/blocks/BlockHeader'
import BlockGroup from '@components/editor/scene/blocks/BlockGroup'

export default {

  components: {
    InputWrapper,
    BlockHeader,
    BlockGroup
  },

  props: {
    node: {
      required: true,
      type: Object
    },
    gui: {
      required: true,
      type: Object
    }
  },

  computed: {

    isMesh () {
      return this.node.type === 'Mesh'
    },

    isGlobalLight () {
      return this.node._isGlobalLight === true
    },

    isIgnoredGroup () {
      return this.node._ignoreExport === true
    },

    isLight () {
      let type = this.node.type
      return (type === 'SpotLight' ||
        type === 'AmbientLight' ||
        type === 'DirectionalLight' ||
        type === 'HemisphereLight' ||
        type === 'PointLight' ||
        type === 'RectAreaLight')
    },

    isGroup () {
      return this.node.type === 'Group'
    },

    isScene () {
      return this.node.type === 'Scene'
    },

    isObject3D () {
      return this.node.type === 'Object3D'
    },

    isAnimation () {
      return this.node.type === 'Animation'
    }
  }
}
